<template>
  <div class="container">
    <br>
    <b-alert class="alert-page-title" show variant="secondary">
      <h4 class="alert-heading">
        {{ $t('COLLECTIONS.title') }}
      </h4>

      <p class="mb-0">
        {{ $t('COLLECTIONS.subtitle') }}
      </p>

      <div class="alert-actions">
        <b-btn v-b-tooltip.hover
          variant="primary"
          v-b-modal.modalCreate
          v-if="isLoggedIn && isPremium"
          title="criar uma Collection"
          >
            <i
              class="fas fa-folder-plus"
            ></i>
            {{ $t('COLLECTIONS.create') }}
        </b-btn>
      </div>
    </b-alert>

    <b-modal id="modalCreate" ref="modalCreate" :title="$t('COLLECTIONS.modal.title')" :hide-footer="true">
      <form @submit.stop.prevent="handleSubmit">
        <b-form-group
          :label="$t('COLLECTIONS.modal.name')"
        >
          <b-form-input type="text" :placeholder="$t('COLLECTIONS.modal.name_placeholder')" v-model="collection.name"></b-form-input>
        </b-form-group>

        <b-form-group
          :label="$t('COLLECTIONS.modal.description')"
        >
          <b-form-textarea
            id="textarea-small"
            :placeholder="$t('COLLECTIONS.modal.description_placeholder')"
            v-model="collection.description"
            rows="2"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          :label="$t('COLLECTIONS.modal.is_public')"
          :description="$t('COLLECTIONS.modal.is_public_description')"
        >
          <b-form-checkbox v-model="collection.is_public" name="check-button" button>
            {{ $t('COLLECTIONS.modal.is_public_label') }}
          </b-form-checkbox>
        </b-form-group>

        <b-btn type="submit" variant="primary">{{ $t('COLLECTIONS.modal.submit') }}</b-btn>
      </form>
    </b-modal>

    <p class="lead">
      {{ $t('COLLECTIONS.table_title') }}
    </p>

    <div class="stats-table table-responsive">
      <table class="table is-hoverable">
        <tbody>
          <tr v-for="collection in collections" :key="collection.id">
            <td>
              <div class="match-collection-single">
                <router-link
                  :to="{ name: 'CollectionSingle', params: { id: collection.to_param }}"
                  class="forum-link"
                >
                  <h4 v-html="collection.name || $t('UTILITY.unnamed') "></h4>
                  <p>{{ collection.description }}</p>
                </router-link>
              </div>
            </td>

            <td class="user-info">
              <small>{{ $t('COLLECTIONS.created_by') }}</small>
              <router-link
                  :to="{ name: 'UserSingle', params: { id: collection.user.to_param }}"
                  class="user-card-link"
                >
                  <div class="user-avatar">
                    <img :src="collection.user.avatar.url">
                  </div>
                  <h4 v-html="collection.user.display_name || 'sem nome' "></h4>
                </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
// import Header from '@/components/Header'
export default {
  name: "CollectionList",
  data() {
    return {
      collections: [],
      collection: { name: "", description: "", is_public: false }
    };
  },
  computed: {
    isPremium: function() {
      return (
        (this.currentUser &&
        this.currentUser.subscription_package &&
        this.currentUser.subscription_package.package != "free") || (this.currentUser && this.currentUser.role == 'role_user_admin')
      );
    }
  },
  methods: {
    initialize() {
      this.$http
        .get("collections.json")
        .then(response => (this.collections = response.data));
    },
    handleSubmit() {
      this.$http.post("collections.json", this.collection).then(response => {
        this.initialize();
        this.$refs.modalCreate.hide();
      });
    }
  },
  mounted() {
    this.initialize();
  },
  components: {}
};
// Header
</script>

<style lang="sass" scoped>
a:not(.light)
  color: #111
  transition: all .3s
  &:hover
    text-decoration: none
    color: $link

.match-collection-single
  a
    h4
      margin-bottom: 0
      font-weight: bold
    p
      margin-bottom: 0
.user-info
  padding: 0 0.75rem
  vertical-align: center
.user-card-link
  display: flex
  align-items: center
  img
    width: 32px
    height: 32px
    border-radius: 250%
    margin: 0 10px 0 0
  h4
    font-size: 16px
    margin-bottom: 0
    
</style>
